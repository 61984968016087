'use strict';

import $ from 'jquery';
import doT from 'dot';
import tabs from 'tabs';

export default class DirectoryCard {
    constructor() {
        let _self = this;

        var $tabsWrap = $('.tab-container'),
            $tabs = $('.tabs .tab:first-child'),
            $tabPane = $('.tab-panes .tab-pane:first-child'),
            $filter = $('.filter_container .filter-select'),
            $letterList = $('.letter-list'),
            $letterLink = $letterList.children('.letter-list--item'),
            $departmentCon = $('.department-container'),
            $filterLetter = $('.department-letter').data('api'),
            $filterCategory = $('.department-category').data('api'),
            append = false;

        if($tabsWrap[0]) {
            var container = document.querySelector('.tab-container');
            tabs(container);

            $tabs.addClass('active');
            $tabPane.addClass('active');
            $filter.niceSelect();

            _self.getByLetter($filterLetter, append);

            setTimeout(function() {
                $('.tab-pane').each(function() {
                    if($(this).hasClass('active')) {
                        // $(this).fadeIn(500);
                        $(this).fadeIn('slow');
                    }
                });
            }, 300);

            $('.tab-item').on('click', function() {
                var $this = $(this);

                // if($this.data('type') == 'letter') {
                if($this.hasClass('tab-letter')) {
                    $('.tab-pane').fadeOut(500);
                    $('.landing-items').each(function() {
                        setTimeout(function() {
                            $('.tab-pane').each(function() {
                                if ($(this).hasClass('active')) {
                                    $(this).fadeIn('slow');
                                }
                            });
                            $('.department--wrap').empty();
                        }, 800);
                    });

                    setTimeout(function() {
                        _self.getByLetter($filterLetter, append);
                    }, 800);
                } else if($this.hasClass('tab-level')) {
                    $('.tab-pane').fadeOut(500);
                    $('.landing-items').each(function() {
                        setTimeout(function() {
                            $('.tab-pane').each(function() {
                                if ($(this).hasClass('active')) {
                                    $(this).fadeIn('slow');
                                }
                            });
                            $('.department--wrap').empty();
                        }, 800);
                    });

                    setTimeout(function() {
                        _self.runLevel(false)
                    }, 800);
                } else if($this.hasClass('tab-category')) {
                    $('.tab-pane').fadeOut(500);
                    $('.landing-items').each(function() {
                        setTimeout(function() {
                            $('.tab-pane').each(function() {
                                if ($(this).hasClass('active')) {
                                    $(this).fadeIn('slow');
                                }
                            });
                            $('.department--wrap').empty();
                        }, 800);
                    });

                    setTimeout(function() {
                        _self.runFilter(false)
                    }, 800);
                }
            });

            // Filter Call API function
            if ($('.filter_container')[0]) {
                $('.filter_container').each(function(){
                    let $this = $(this);

                    if ($this.find('select').length) {
                        $this.find('select').on('change', function() {
                            var $sel = $(this),
                                $selVal = $sel.val();
                            setTimeout(function() {
                                $.fn.matchHeight._update();
                                $sel.parents('.filter_container').data('value', $selVal);
                            }, 350);
                            $sel.parents('.filter_container').attr('data-value', $selVal);
                            setTimeout(function() {
                                $('.department--wrap').fadeOut();
                                $('.department--wrap').empty();
                            }, 500);

                            setTimeout(function() {
                                $('.department--wrap').fadeIn();
                                if ($sel.parents('.filter_container').hasClass('filter-category')) {
                                    _self.runFilter(false);
                                    console.log('Category');
                                } else {
                                    if ($sel.parents('.filter_container').hasClass('filter-level')) {
                                        _self.runLevel(false);
                                        console.log('Level');
                                    }
                                } 
                            }, 800);
                        });
                    }
                });
            }
        }
    }

    runFilter(appendContent) {
		let _self = this,
            append = appendContent || false,
			$container = $('.department-category'),
            $filterLetter = $container.data('api');

        var $url = window.location,
            $queryStr = window.location.search;

		var activeFilter = _self.getActiveFilterCat();

        var $paramData = activeFilter;

        // Check URL for query strings
        if($queryStr[0]) {
            // query string exist
            _self.updateParam();
            window.history.replaceState(null, null, $url + "?" + $.param(activeFilter));
        } else {
            // no query string exists
            window.history.replaceState(null, null, $url + "?" + $.param(activeFilter));
        }

		_self.getByCategory($filterLetter, activeFilter, append);
	}

    runLevel(appendContent) {
		let _self = this,
            append = appendContent || false,
			$container = $('.department-level'),
            $filterLetter = $container.data('api');

        var $url = window.location,
            $queryStr = window.location.search;

		var activeFilter = _self.getActiveFilterLvl();

        var $paramData = activeFilter;

        // Check URL for query strings
        if($queryStr[0]) {
            // query string exist
            _self.updateParam();
            window.history.replaceState(null, null, $url + "?" + $.param(activeFilter));
        } else {
            // no query string exists
            window.history.replaceState(null, null, $url + "?" + $.param(activeFilter));
        }

		_self.getByLevel($filterLetter, activeFilter, append);
	}

    getByLetter(url, appendContent) {
		let _self = this;

        var $container = $('.department-letter'),
            $filterLetter = $container.data('api'),
			$listingContainer = $container.children('.department--wrap'),
			$modalTemplate = doT.template($('#dirTemplate').html()),
            append = appendContent || false;
            // $letterList = $('.letter-list'),
            // $letterLink = $letterList.children('.letter-list--item'),
            // $departmentCon = $('.department-container');

		$.ajax({
			url,
			method: 'get',
			dataType: 'json'
		})
		.done(function(data) {
            
            // Get Available letters
            var array = [];
            for (var key in data) {
                array.push(data[key].letter)
            }

            $('.letter-list--item[data-sort="' + array.join('"], [data-sort="') + '"]').removeClass('disabled');

            _self.updateParam();

			let _renderedHTML = $modalTemplate(data);

            $listingContainer.append(_renderedHTML);

            setTimeout(function(){
                var title = $('.item--detail-info .icon-website a');
                
                title.each(function () {
                    var $this = $(this),
                        $thisLength = $this.text().length;

                    if ($thisLength > 35) {
                        var shortText = jQuery.trim($this.text()).substring(0, 35).split("").slice(0, -1).join("") + "...";

                        $this.text(shortText);
                    }
                });
            }, 300);

            var $letterList = $('.letter-list'),
                $letterLink = $letterList.children('.letter-list--item'),
                $departmentCon = $('.department-container');

            $letterLink.on('click', function() {
                var $this = $(this);
                
                if ($this.hasClass('disabled')) {
                    console.log('No Records Found!');
                } else {
                    $letterLink.removeClass('is-current');
                    $this.addClass('is-current');

                    var filterVal = $this.data('sort');

                    if(filterVal == 'All') {
                        $departmentCon.fadeOut('slow');
                        setTimeout(function() {
                            $departmentCon.fadeIn('slow');
                        }, 500);
                    } else {
                        $departmentCon.each(function () {
                            if ($(this).data('sort') != filterVal) {
                                $(this).fadeOut('slow');
                            } else {
                                $departmentCon.fadeOut('slow');
                                $(this).fadeIn('slow');
                            }
                        });
                    }
                }
            }); 
		})
		.fail(function(jqXHR) {
			console.error('Fail to connect to API.', jqXHR);
		});
    }
    
    getByLevel(url, data, appendContent) {
		let _self = this;

        var $container = $('.department-level'),
            $filterLetter = $container.data('api'),
			$listingContainer = $container.children('.department--wrap'),
			$modalTemplate = doT.template($('#dirTemplate').html()),
            append = appendContent || false;

		$.ajax({
            url,
            data,
			method: 'get',
			dataType: 'json'
		})
		.done(function(data) {
            // console.log(data);
			let _renderedHTML = $modalTemplate(data);

            var $mapImg = $('.level-map-img');
            var $mapLvl = _self.qs('level');

            if($mapLvl) {
                var $mapLvl = $mapLvl.replace(/\+/g, " ");
                
                $mapImg.each(function() {
                    var $this = $(this);

                    if ($this.data('level') == $mapLvl) {
                        $mapImg.fadeOut();
                        $this.fadeIn();
                    } else {
                        $this.fadeOut();
                    }
                });
            }

            $listingContainer.append(_renderedHTML);

            setTimeout(function(){
                var title = $('.item--detail-info .icon-website a');
                
                title.each(function () {
                    var $this = $(this),
                        $thisLength = $this.text().length;

                    if ($thisLength > 35) {
                        var shortText = jQuery.trim($this.text()).substring(0, 35).split("").slice(0, -1).join("") + "...";

                        $this.text(shortText);
                    }
                });
            }, 300);
		})
		.fail(function(jqXHR) {
			console.error('Fail to connect to API.', jqXHR);
		});
    }

    getByCategory(url, data, appendContent) {
		let _self = this;

        var $container = $('.department-category'),
            $filterLetter = $container.data('api'),
			$listingContainer = $container.children('.department--wrap'),
			$modalTemplate = doT.template($('#dirTemplate').html()),
            append = appendContent || false;

		$.ajax({
            url,
            data,
			method: 'get',
			dataType: 'json'
		})
		.done(function(data) {
            // console.log(data);
			let _renderedHTML = $modalTemplate(data);

            $listingContainer.append(_renderedHTML);

            setTimeout(function(){
                var title = $('.item--detail-info .icon-website a');
                
                title.each(function () {
                    var $this = $(this),
                        $thisLength = $this.text().length;

                    if ($thisLength > 35) {
                        var shortText = jQuery.trim($this.text()).substring(0, 35).split("").slice(0, -1).join("") + "...";

                        $this.text(shortText);
                    }
                });
            }, 300);
		})
		.fail(function(jqXHR) {
			console.error('Fail to connect to API.', jqXHR);
		});
    }
    
    paramIsEmpty(value) {
        let _self = this;
        return value == null || value == "";
    }

    updateParam() {
        let _self = this;
        
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState(null, null, clean_uri);
        }
    }

    getActiveFilterCat() {
		let _self = this,
            $filter = $('.filter-category'),
            $select = $filter.children('.filter-select'),
			$data = {};

        $filter.each(function() {
            let $this = $(this),
                $key = $this.data('key'),
                $value = $this.data('value');
            
            // Check if data is empty
            if (_self.paramIsEmpty($value)) {
                delete $data[$key];
            } else {
                $data[$key] = $value;
            }
        });
        // console.log($data);
        return $data;
	}

    getActiveFilterLvl() {
		let _self = this,
            $filter = $('.filter-level'),
            $select = $filter.children('.filter-select'),
			$data = {};

        $filter.each(function() {
            let $this = $(this),
                $key = $this.data('key'),
                $value = $this.data('value');
            
            // Check if data is empty
            if (_self.paramIsEmpty($value)) {
                delete $data[$key];
            } else {
                $data[$key] = $value;
            }
        });
        // console.log($data);
        return $data;
	}

    qs(key) {
		let _self = this,
			query = window.location.search.substring(1),
			parms = query.split('&');

		for (var i=0; i<parms.length; i++) {
			var pos = parms[i].indexOf('=');
			if (pos > 0  && key == parms[i].substring(0,pos)) {
				return parms[i].substring(pos+1);
			}
		}
		return "";
	}
}
