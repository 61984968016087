'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class Carousel {
    constructor() {
        let _self = this;

        var $galleryCarousel = $('.article-gallery__slick');

        // Article Gallery
        if($galleryCarousel[0]) {
            $galleryCarousel.slick({
                autoplay: true,
                autoplaySpeed: 10000,
                infinite: true,
                fade: true,
                dots: true,
                arrows: true,
                mobileFirst: true,
                speed: 800,
                appendDots: $('.article-gallery__dots'),
                appendArrows: $('.article-gallery__arrows'),
                prevArrow: $('.article-gallery__arrows .prev'),
                nextArrow: $('.article-gallery__arrows .next')
            });
        }

    }
}
