// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'hover-intent';
import 'jquery-match-height';
import 'sticky-kit';
import 'jquery-match-height';
import 'jquery-asPieProgress';
import 'jquery-nice-select';
import slimScroll from 'jquery-slimscroll';
import fancybox from 'fancybox';
import tabs from 'tabs';
import emitter from 'tiny-emitter/instance';
import Headroom from 'headroom.js';
import doT from 'dot';
import Masonry from 'masonry-layout';
import moment from 'moment';
import objectFitImages from 'object-fit-images';
import Pikaday from 'pikaday';
import ProgressBar from 'progressbar.js';
import enquire from 'enquire.js';
import TweenLite from 'gsap'
import waypoints from 'waypoints';
import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import { debounce, detectIE, timing, bp } from '_helper';

window.doT = doT;
window.moment = moment;

import ImageCard from 'atoms/image-card/image-card';
import Carousel from 'molecules/carousel/carousel';
import ImageListCard from 'molecules/image-list-card/image-list-card';
import ImageListIsotope from 'molecules/image-list-isotope/image-list-isotope';
import DirectoryCard from 'molecules/directory-card/directory-card';
import BannerCarousel from 'molecules/banner-carousel/banner-carousel';
import Header from 'organisms/header/header';
import HomeCarousel from 'organisms/home-carousel/home-carousel';
import HomeVideo from 'organisms/home-video/home-video';
import HomeAbout from 'organisms/home-about/home-about';
import HomePartners from 'organisms/home-whatson/home-whatson';
import HomeWhatson from 'organisms/home-partners/home-partners';
import HomeConnect from 'organisms/home-connect/home-connect';

$(() => {
    let _self = this;

    window.scapesg = new Object();

    window.AD = {};
	window.AD.emitter = emitter;

    // Activate modules logic
    new Header();
    new ImageCard();
            
    // Check if Homepage
    if($('.home-carousel, .home-video')[0]) {
        $('header').addClass('is-home');
        
    } else {
        $('header').removeClass('is-home');
    }

    new HomeCarousel();
    new HomeVideo();

    if($('.home-about')[0]) {
        new HomeAbout();
    }

    if($('.home-whatson')[0]) {
        new HomeWhatson();
    }

    // if($('.home-connect')[0]) {
    //     new HomeConnect();
    // }

    if($('.home-partners')[0]) {
        new HomePartners();
    }

    if($('.banner-carousel')[0]) {
        new BannerCarousel();
    }

    if($('.image-list-card')[0]) {
        new ImageListCard();
    }

    if($('.department')[0]) {
        new DirectoryCard();   
    }

    // Article Gallery Carousel
    if($('.article-gallery__slick')[0]) {
        new Carousel();
    }

    // Scroll to Top button
	$('.scrollToTop').on('click', function(e) {
		e.preventDefault();
		return $('html, body').animate({
			scrollTop: 0
		}, 800);
		return false;

		$(this).blur();
	});

    // Clone sidebar
    // Mobile & Tablet
    enquire.register("screen and (max-width: " + bp.maxMobile + "px)", {
        match : function() {
            $('aside.sidebar').clone().prependTo('.main').addClass('clone-aside');
            setTimeout(function(){
                $('ul.sidebar__list').each(function(){
                    var list = $(this),
                        select = $(document.createElement('select')).addClass('sidebar__list').insertBefore($(this).hide());
                   
                    $('>li a', this).each(function(){
                        var option=$(document.createElement('option')).appendTo(select).val(this.href).html($(this).html());
                        if($(this).attr('class') === 'selected'){
                            option.attr('selected','selected');
                        }
                    });
                    // list.remove();
                });

                var $cloneSelect = $('.clone-aside > .sidebar-sticky > select.sidebar__list');
                $cloneSelect.niceSelect();

                $('.sidebar-sticky .list li').on('click', function () {
                    var url = $(this).data('value');
                    if (url) {
                        window.location = url;
                    }
                    return false;                    
                });
            }, 200);
        },
        unmatch : function() {
            $('.clone-aside').remove();
            $('select.sidebar__list').remove();
            $('ul.sidebar__list').show();
        }
    });

    // Remove Height & Width Attribute for logo
    var $imgLogo = $('.logo-img');

    if ($imgLogo[0]) {
        $imgLogo.each(function() {
            var $this = $(this);
            
            $this.find('img').removeAttr('height').removeAttr('width');
            // $this.show();
            setTimeout(function(){
                $this.show(200);
            }, 300);
        });
    }

    var $iframe = $('iframe');

    if($iframe.length) {
        $iframe.map(function() {
            var $this = $(this);
            var frameClass = $this.hasClass('venue-frame') ? 'iframe-wrapper venue-frame' : 'iframe-wrapper';

            $this.removeAttr('height').removeAttr('width').wrap(`<div class="${frameClass}"></div>`);
        });
	}

    // Responsive Table
    var $table = $('table');
    if ($table[0]) {
        $table.each(function(){
            var $this = $(this);
            $('<div class="rteTable"></div>').insertBefore($this).append($this); 
        });
    }

    // SVG logo image
    $('img.svg').each(function(){
		var $img = jQuery(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');

		jQuery.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = jQuery(data).find('svg');

			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}

			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');

			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
	});

    // Fallback for IE Objectfit
	var userAgent, ieReg, ie;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);

    setTimeout(function(){
        if (ie) {
            $('.image-container, .img-card-item__image, .home-carousel .home-carousel__slick .slick-image, .home-video .video-image, .j-image img, .home-about__gallery .gallery-image, .banner-carousel .slick-image').each(function () {
                var $container = $(this),
                imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                    $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('compact-object-fit');
                }
            });
        }
    }, 300);

    $('#es_txt_email').attr('placeholder', 'Enter your email address');
});
