'use strict';

import $ from 'jquery';
import 'hover-intent';
import doT from 'dot';
import enquire from 'enquire.js';
import { bp } from '_helper';

export default class ImageListIsotope {
    constructor() {
        let _self = this;

        var $storyCard = $('.image-list-isotope > .list-container > .row > .list-wrap > .img-card');

        var msnry = new Masonry('.list-container > .row', {
            itemSelector: '.list-wrap',
            columnWidth: '.list-wrap',
            percentPosition: true
        });

        // Desktop View
        enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
			match : function() {
                $storyCard.hoverIntent({
                    over: function() {
                        var $thisCard = $(this),
                            $carditem = $thisCard.find('.item-readmore__wrap');
        
                        $carditem.addClass('is-active');
                        $carditem.fadeIn();
                    },
                    out: function() {
                        $(this).find('.item-readmore__wrap').removeClass('is-active');
                        $(this).find('.item-readmore__wrap').fadeOut();
                    },
                    timeout: 100,
                    sensitivity: 1,
                    interval: 100,
                });
			}
        });

        var $listContainer = $('.image-list-isotope'),
            $listWrap = $('.list-container'),
            $loadBtn = $('.js-load-more', $listContainer),
            $listTemplate = doT.template($('#isotopeTemplate').html());

        var append = false;
        
        _self.getMoreItem($listContainer.data('api'), append);
        
    }

    getMoreItem(url, appendContent) {
        let _self = this;

        var $listContainer = $('.image-list-isotope'),
            $listRow = $('.list-container > .row', $listContainer),
            $loadmore = $('.js-load-more', $listContainer),
            $modalTemplate = doT.template($('#isotopeTemplate').html()),
            append = appendContent || false;
        
        var data = {
            "pageid": $listContainer.data('pageid')
        }

        $.ajax({
            url,
            method: 'get',
            data: data,
            dataType: 'json',
            cache: true
        }).done(function(data) {
            let _renderedHTML = $modalTemplate(data);

            // if (!append) {
			// 	$listRow.addClass('is-loading').empty();
            // }

            setTimeout(function() {
                $listRow.removeClass('is-loading').append(_renderedHTML);

                $('.listing-wrap').fadeIn('slow');

                // Desktop View
                enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
                    match : function() {
                        window.AD.emitter.emit('cardInfo');
                    }
                });

                enquire.register("screen and (min-width: " + (bp.maxMobile + 1) + "px)", {
                    match : function() {
                        $('.item-desc').matchHeight({
                            byRow: false
                        });

                        $('.item-info').matchHeight();
                    }
                });
            }, 300);

            console.log(data);

            // if (!append) {
			// 	$listRow.addClass('is-loading').empty();
            // }

            // setTimeout(function() {
            //     $listRow.removeClass('is-loading').append(_renderedHTML);

            //     $('.listing-wrap').fadeIn('slow');

            //     // Desktop View
            //     enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
            //         match : function() {
            //             window.AD.emitter.emit('cardInfo');
            //         }
            //     });

            //     enquire.register("screen and (min-width: " + (bp.maxMobile + 1) + "px)", {
            //         match : function() {
            //             $('.item-desc').matchHeight({
            //                 byRow: false
            //             });

            //             $('.item-info').matchHeight();
            //         }
            //     });
            // }, 300);

            // // Show/Hide Load More Button
            // setTimeout(function() {
            //     $('.list-wrap').each((i,item)=>{
            //         if (i < 6) {
            //             $('.image-list-card__btn').hide();
            //         } else {
            //             $('.image-list-card__btn').show();
            //         }
            //     });
            // }, 1000);

            // // Fallback for IE Objectfit
            // var userAgent, ieReg, ie;
            // userAgent = window.navigator.userAgent;
            // ieReg = /msie|Trident.*rv[ :]*11\./gi;
            // ie = ieReg.test(userAgent);

            // setTimeout(function(){
            //     if (ie) {
            //         $('.image-container, .img-card-item__image, .home-carousel .home-carousel__slick .slick-image, .home-video .video-image, .j-image img, .home-about__gallery .gallery-image, .banner-carousel .slick-image').each(function () {
            //             var $container = $(this),
            //             imgUrl = $container.find('img').prop('src');
            //             if (imgUrl) {
            //                 $container
            //                     .css('backgroundImage', 'url(' + imgUrl + ')')
            //                     .addClass('compact-object-fit');
            //             }
            //         });
            //     }
            // }, 300);

            // $loadmore.click((e) => {
            //     e.preventDefault();
                
            //     let $parent = $(e.currentTarget).parent().parent(),
            //         $listContainer = $parent.siblings('.list-wrap');

            //     if ($listContainer.length < 12) {
            //         $(e.currentTarget).hide();
            //     }
                
            //     $('.list-wrap.hidden').each((i,item)=>{
            //         if (i < 12) {
            //             $(item).removeClass('hidden');
            //             $(e.currentTarget).hide();
            //         } else {
            //             $(e.currentTarget).show();
            //         }
            //     });
            // });
        });
    }
}
