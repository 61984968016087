'use strict';

import $ from 'jquery';
import fancybox from 'fancybox';
// import enquire from 'enquire.js';
// import { debounce, detectIE, timing, bp, iOS } from '_helper';

export default class HomeVideo {
    constructor() {
        let _self = this;

        if($('.home-video')[0]) {
            $('.btn--play').fancybox({
                padding : 0,
                openEffect	: 'elastic',
                closeEffect	: 'elastic',
                openEasing : 'swing',
                closeEasing : 'swing',
                autoSize: true,
                type   : 'iframe'
            });
        }
    }
}
