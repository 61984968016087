'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import emitter from 'tiny-emitter/instance';
import enquire from 'enquire.js';
import { bp } from '_helper';

export default class ImageCard {
    constructor() {
        let _self = this;

        window.AD.emitter.on('cardInfo', function(){
            $('.item-desc').matchHeight({
                byRow: false
            });
            $('.item-title').matchHeight({
                byRow: false
            });
            $('.item-info').matchHeight({
                byRow: false
            });
            
            var $cardInfo = $('.img-card-item__info'),
                $catH = $('.item-cat').outerHeight(),
                $titleH = $('.item-title').outerHeight(),
                $infoH = $('.item-info').outerHeight(),
                $descH = $('.item-desc').outerHeight(true),
                $btnH = $('.btn-img-card').outerHeight(),
                $totalH = $catH + $titleH + $infoH;
            
            $cardInfo.css({
                'height': $totalH + 54
            });

            $cardInfo.each(function() {
                var $this = $(this);

                $this.on('mouseenter', function() {
                    $this.css({
                        'height': $totalH + $descH + $btnH + 54
                    }).on('mouseleave', function() {
                        $this.css({
                            'height': $totalH + 54
                        });
                    })
                });
            });
        });

        // Desktop View
        enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
			match : function() {
                window.AD.emitter.emit('cardInfo');
			}
        });
    }
}
