'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class BannerCarousel {
    constructor() {
        let _self = this;

        var $bannerCarousel = $('.banner-carousel__slick'),
            $status = $('.banner__paging');

        $bannerCarousel.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text('0'+ i + ' / ' + '0' + slick.slideCount);

            var totalProjectSlide = slick.slideCount;

            $bannerCarousel.fadeIn('fast');
        });

        // Banner Carousel
        if($bannerCarousel[0]) {
            $bannerCarousel.slick({
                autoplay: true,
                autoplaySpeed: 10000,
                infinite: true,
                fade: true,
                dots: true,
                arrows: false,
                mobileFirst: true,
                speed: 800,
                appendDots: $('.banner-carousel__dots .container .carousel-dots__wrap')
            });
        }
    }
}
