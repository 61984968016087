'use strict';

export default class HomePartners {
    constructor() {
        let _self = this;

        var $homePartners = $('.partners-carousel__slick');

        $homePartners.slick({
            speed: 800,
            fade: false,
            infinite: true,
            arrows: true,
            mobileFirst: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            appendArrows: $('.partners-carousel__arrows'),
            prevArrow: $('.partners-carousel__arrows .prev'),
            nextArrow: $('.partners-carousel__arrows .next')
        });
    }
}
