'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';
import doT from 'dot';
import enquire from 'enquire.js';
import { bp } from '_helper';

export default class HomeConnect {
    constructor() {
        let _self = this;

        var $socialSlider = $('.home-connect__social'),
            $socialDesc = $('.instagram-desc > .desc-text');
        
        _self.hashTag();
        _self.userTag();

        if($('.social-card')[0]) {
            setTimeout(function(){
                $(document).ready(function() {
                    var $jFeed = $('.feed-item'),
                        $jImage = $('.j-image'),
                        $jPoster = $('.j-poster'),
                        $jText = $('.j-text');
                    
                    $jFeed.prepend($jText).prepend($jPoster).prepend($jImage);

                    if($('.j-gallery-nav')[0]) {
                        setTimeout(function(){
                            var $jGalleryPrev = $('.j-gallery-previous'),
                                $jGalleryNext = $('.j-gallery-next');
                            
                            $jGalleryPrev.text('').addClass('icon icon-arrow-l');
                            $jGalleryNext.text('').addClass('icon icon-arrow-r');
                        }, 1000);
                    }
                });
            }, 1000);
        }

        // Mobile & Tablet
        enquire.register("screen and (max-width: " + (bp.minDesktop - 1) + "px)", {
		    match : function() {
                
                $socialSlider.on('init', function(event, slick, currentSlide, nextSlide){
                    
                    if($('.instagram-desc')[0]) {
                        var title = $('.social__instagram--hash .instagram-desc .desc-text');
                        var shortText = jQuery.trim(title.text()).substring(0, 250).split(" ").slice(0, -1).join(" ") + "...";

                        title.text(shortText);
                        $.fn.matchHeight._update();
                    }
                    
                    setTimeout(function(){
                        $('.social-card:not(:first-child)').matchHeight();
                        $('.instagram-img, .facebook-img, .j-image').matchHeight();
                    }, 500);
                });
                
                setTimeout(function(){
                    $socialSlider.slick({
                        infinite: false,    
                        speed: 800,
                        dots: false,
                        arrows: false,
                        mobileFirst: false,
                        centerMode: true,
                        centerPadding: '50px',
                        slidesToShow: 1
                    });

                    $socialSlider.slick('slickGoTo', 1);
                }, 800);
			},
		    unmatch : function() {
                $socialSlider.slick('unslick');
                
                setTimeout(function(){
                    $.fn.matchHeight._update();
                }, 800);
		    }
		});
    }

    userTag() {
        let _self = this;

        var $socialSlider = $('.home-connect__social'),
            $userCon = $('.social__instagram--user'),
            $token = $userCon.data('token'),
            $desc = $userCon.children('.desc-text'),
            $instagramAPI = 'https://api.instagram.com/v1/users/self/media/recent/?count=1&access_token=',
            imgList = {};

        $.ajax({
            url: $instagramAPI + $token,
            method: 'get',
            dataType: 'json',
            cache: true
        }).done(function(data) {
            console.log(data);
            imgList = data;                
            let instaUserTemplate = doT.template($('#instaUser').html());
            let _renderedHTML = instaUserTemplate(imgList);

            $userCon.html(_renderedHTML);

            // Match height Instagram box
            setTimeout(function(){
                if($('.instagram-desc')[0]) {

                    var title = $('.social__instagram--user .instagram-desc .desc-text');
                    var shortText = jQuery.trim(title.text()).substring(0, 250).split(" ").slice(0, -1).join(" ") + "...";

                    title.text(shortText);
                }
            }, 100);
            
        }).fail(function(jqXHR) {
            console.error('Fail to connect to API.', jqXHR);
        });
    }

    hashTag() {
        let _self = this;
        
        var $hashCon = $('.social__instagram--hash'),
            $search = $hashCon.data('hashtag'),
            $posts = $hashCon.data('postno');
        
        var searchUrl  = 'https://www.instagram.com/explore/tags/' + $search + '/?__a=1';
        var type = '';
        
        $.get(searchUrl, function(data, status) {
            console.log(data);

            for(var i = 0; i < $posts; i++) {
                if($('.social__instagram--hash > .instagram-img').length == $posts) {
                    $('.social__instagram--hash > .instagram-img').remove();
                }
                
                var $this = data.graphql.hashtag.edge_hashtag_to_media.edges[i].node;
                var $userShortCode = 'https://www.instagram.com/p/' + $this.shortcode + '/?tagged=scapesg';
                // var $userAPI = 'https://www.instagram.com/graphql/query/?query_hash=292c781d60c07571d58d9ef7808888ef&variables=%7B%22shortcode%22%3A%22' + $this.shortcode + '%22%2C%22include_reel%22%3Atrue%2C%22include_logged_out%22%3Afalse%7D';

                // $.ajax({
                //     url: $userAPI,
                //     method: 'get',
                //     dataType: 'json',
                //     cache: true
                // }).done(function(dataUser) {
                //     console.log(dataUser);
                // }).fail(function(jqXHR) {
                //     console.error('Fail to connect to API.', jqXHR);
                // });
                
                $hashCon.append('<a href="' + $userShortCode + '" target="_blank"><div class="instagram-img"><img src="' + $this.thumbnail_resources[2].src + '"/></div><div class="instagram-desc"><div class="desc-text">' + $this.edge_media_to_caption.edges[0].node.text.toLocaleString() + '</div></div></a>');

                // Match height Instagram box
                setTimeout(function(){
                    if($('.instagram-desc')[0]) {

                        var title = $('.social__instagram--hash .instagram-desc .desc-text');
                        var shortText = jQuery.trim(title.text()).substring(0, 250).split(" ").slice(0, -1).join(" ") + "...";

                        title.text(shortText);

                        $('.instagram-desc').matchHeight();
                        $('.social-wrap').matchHeight();
                    }
                }, 100);
            }
        }).fail(function(jqXHR) {
            console.error('Fail to connect to API.', jqXHR);
        });
    }
}
