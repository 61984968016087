'use strict';

import $ from 'jquery';
import 'sticky-kit';
import Headroom from 'headroom.js';
import 'hover-intent';
import enquire from 'enquire.js';
import { bp, iOS } from '_helper';

export default class Header {
    constructor() {
        let _self = this;

        var myHeader = document.querySelector('header');

        var $mainHeader = $('.main-header'),
            $nav = $('.navigation'),
            $lvl1Menu = $('.nav-main__features > .megamenu > li'),
            // $lvl2Menu = $('.menu-col__lvl2 > li'),
            $lvl2Menu = $('.nav-main__features > .megamenu > li > ul > li'),
            $subUL = $('.menu-col__links'),
            $btnSearch = $('.btn--search'),
            $mobileMenuButton = $('.hamburger-menu');

        var headroom = new Headroom(myHeader, {
            "offset" : 10,
            "tolerance" : {
                up : 150,
                down : 10
            },
            "classes" : {
                "initial" : "animated",
                "pinned" : "headroom--pinned",
                "unpinned" : "headroom--unpinned"
            }
        });

        window.scapesg.currentPosition = 0;

        headroom.init();

        $btnSearch.on('click', function(){
            var $form = $(this).closest('form'),
                searchInput = $form.find('#search-input').val();

            if(searchInput != '') {
                $form.submit();
            }
        });

        //Adding Arrow if Menu has Children
        var primaryNavsLIParents = $('.nav-main ul.menu li ul.sub-menu');
        primaryNavsLIParents.before('<button class="btn btn--nav btn--noanime mobile-only" type="button"><span><i class="icon icon-arrow-down"></i><span class="btn__text"></span></span></button>');

        // Desktop View
        enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
			match : function() {
                $lvl1Menu.hoverIntent({
                    over: function() {
                        var $thisLi = $(this),
                            // $navitem = $thisLi.children('.nav-main__item');
                            $navitem = $thisLi.children('a');

                        $navitem.addClass('is-active');

                        // $thisLi.find('.megamenu__dropdown.child').slideDown(200);
                        // $thisLi.find('.menu-col__lvl2.menu-col').slideDown(200);
                        $navitem.siblings('.sub-menu').slideDown(500);

                        setTimeout(function(){
                        	// $thisLi.find('.megamenu__dropdown.child').addClass('is-active');
                            // $thisLi.find('.menu-col__lvl2.menu-col').addClass('is-active');
                            $navitem.siblings('.sub-menu').addClass('is-active');
                        }, 300);
                    },
                    out: function() {
                        // $(this).children('.nav-main__item').removeClass('is-active');
                        // $(this).find('.megamenu__dropdown.child').removeClass('is-active');
                        // $(this).find('.megamenu__dropdown.child').slideUp(300);
                        $(this).children('a').removeClass('is-active');
                        // $(this).find('.menu-col__lvl2.menu-col').removeClass('is-active');
                        // $(this).find('.menu-col__lvl2.menu-col').slideUp(300);
                        $(this).children('a').siblings('.sub-menu').removeClass('is-active');
                        $(this).children('a').siblings('.sub-menu').slideUp(500);
                    },
                    timeout: 200,
                    sensitivity: 1,
                    interval: 200,
                });

                $lvl2Menu.hoverIntent({
                    over: function() {
                        var $thisLi = $(this),
                            // $nav2item = $thisLi.children('.nav-sub__item');
                            $nav2item = $thisLi.children('a');

                        $nav2item.addClass('is-active');

                        // $thisLi.find('.megamenu__dropdown.sub-grandchild').slideDown(200);
                        // $thisLi.find('.menu-col__lvl3.menu-col').slideDown(200);
                        $nav2item.siblings('.sub-menu').slideDown(500);

                        setTimeout(function(){
                        	// $thisLi.find('.megamenu__dropdown.sub-grandchild').addClass('is-active');
                            // $thisLi.find('.menu-col__lvl3.menu-col').addClass('is-active');
                            $nav2item.siblings('.sub-menu').addClass('is-active');
                        }, 300);

                        var off = $thisLi.offset();
                        var l = $thisLi.left;
                        var w = $thisLi.width();
                        var docW = $(".container").width();

                        var isEntirelyVisible = (l + w <= docW);

                        if (!isEntirelyVisible) {
                            $(this).addClass('edge');
                        } else {
                            $(this).removeClass('edge');
                        }
                    },
                    out: function() {
                        // $(this).children('.nav-sub__item').removeClass('is-active');
                        // $(this).find('.megamenu__dropdown.sub-grandchild').removeClass('is-active');
                        // $(this).find('.megamenu__dropdown.sub-grandchild').slideUp(300);
                        $(this).children('a').removeClass('is-active');
                        // $(this).find('.menu-col__lvl3.menu-col').removeClass('is-active');
                        // $(this).find('.menu-col__lvl3.menu-col').slideUp(300);
                        $(this).children('a').siblings('.sub-menu').removeClass('is-active');
                        $(this).children('a').siblings('.sub-menu').slideUp(500);
                    },
                    timeout: 500,
                    sensitivity: 1,
                    interval: 400,
                });
			},
            unmatch : function() {
                
            }
        });

        // Mobile & Tablet
        enquire.register("screen and (max-width: 1024px)", {
		    match : function() {
		    	$mobileMenuButton.on('click', function(){
		    		if(!$nav.hasClass('expanded')){
                        $(this).addClass('active');
                        $mainHeader.addClass('menu-open');
                        setTimeout(function(){
                            $mobileMenuButton.addClass('rotate');
                            $nav.addClass('expanded');
                        }, 300);
                        setTimeout(function(){
                            _self.openMenu();
                        }, 300);
		    		}
	    			else{
                        $nav.removeClass('expanded');
                        $(this).removeClass('rotate');
                        _self.closeMenu();
	    				setTimeout(function(){
                            $mobileMenuButton.removeClass('active');
                            $mainHeader.removeClass('menu-open');
                        }, 300);
	    			}
		    	});

    			$('.megamenu > li').on('click', '.btn--nav',function(e) {
                    let $this = $(this);

                    if($this.hasClass('is-active')) {
                        _self.collapseLink($this);
                    } else {
                        _self.expandLink($this);
                    };
                });
			},

		    unmatch : function() {
		    	$nav.removeClass('expanded');
		    	$('.active').removeClass('active');
		    	$('.inactive').removeClass('inactive');

		    	//unbind click events
		    	$mobileMenuButton.off('click');

    	    	//font-resizer
    	    	if(!$('.default').hasClass('active')){
    				$('.default').addClass('active');
    	    	}
		    }
		});
    }

    capturePosition() {
        let _self = this,
            currentPosition = $(window).scrollTop();

        return currentPosition;
    }

    openMenu() {
        let _self = this;

        if (iOS) {
            window.scapesg.currentPosition = _self.capturePosition();
            $('html,body').animate({ scrollTop: 0});
            $('body').addClass('no-scroll');
        }
    }

    closeMenu() {
        let _self = this;

        if (iOS) {
            $('html,body').animate({ scrollTop: window.scapesg.currentPosition});
            $('body').removeClass('no-scroll');
        }
    }

    expandLink($this) {
        let _self = this;

        $this.addClass('is-active');
        $this.parent('li').addClass('is-active');
        $this.find('.icon').removeClass('icon-arrow-down');
        $this.find('.icon').addClass('icon-arrow-up');
        // $this.siblings('.megamenu__dropdown').slideDown(200);
        // $this.siblings('.menu-col').slideDown(200);
        $this.siblings('.sub-menu').slideDown(200);
    }

    collapseLink($this) {
        let _self = this;

        $this.removeClass('is-active');
        $this.blur();
        $this.parent('li').removeClass('is-active');
        $this.find('.icon').removeClass('icon-arrow-up');
        $this.find('.icon').addClass('icon-arrow-down');
        // $this.siblings('.megamenu__dropdown').slideUp(200);
        // $this.siblings('.menu-col').slideUp(200);
        $this.siblings('.sub-menu').slideUp(200);
    }
}
