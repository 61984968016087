'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';
import enquire from 'enquire.js';
import { bp } from '_helper';

export default class HomeWhatson {
    constructor() {
        let _self = this;

        var $whatsSlider = $('.whatson-slider');

        // $('.item-title').matchHeight({byRow: false});
        // $('.item-info').matchHeight({byRow: false});
        // $('.item-desc').matchHeight({byRow: false});

        // Mobile & Tablet
        enquire.register("screen and (max-width: " + (bp.minDesktop - 1) + "px)", {
		    match : function() {
                $whatsSlider.on('init', function(event, slick, currentSlide, nextSlide){
                    $('.img-card-item__info').matchHeight();
                });

                $whatsSlider.slick({
                    speed: 800,
                    dots: false,
                    arrows: false,
                    mobileFirst: true,
                    centerPadding: '50px',
                    centerMode: true,
                    slidesToShow: 1
                });

                var whatsOnResize = function() {
                    var $homeConnect = $('.home-connect'),
                        $whatsOnContainer = $('.home-whatson__info').children('.container').outerHeight(),
                        $slickHeight = $('.img-card-item').outerHeight(),
                        $slickNewHeight = $slickHeight;
                
                    $('.home-whatson__list').css({
                        'top': $whatsOnContainer
                    });

                    $homeConnect.css({
                        'padding-top': $slickNewHeight - $whatsOnContainer
                    });
                }

                setTimeout(function(){
                    whatsOnResize();
                }, 500);

                window.addEventListener('resize', function () {
                    setTimeout(function(){
                        whatsOnResize();
                    }, 500);
                });
			},
		    unmatch : function() {
                $whatsSlider.slick('unslick');
		    }
        });
    }
}
