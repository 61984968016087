'use strict';

import $ from 'jquery';
import doT from 'dot';
import emitter from 'tiny-emitter/instance';
import Pikaday from 'pikaday';
import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import Masonry from 'masonry-layout';
import enquire from 'enquire.js';
import { bp } from '_helper';

export default class ImageListCard {
    constructor() {
        let _self = this;

        var $listContainer = $('.image-list-card'),
            $listWrap = $('.list-container'),
            // $api = $listContainer.data('api'),
            $loadBtn = $('.js-load-more', $listContainer),
            $listTemplate = doT.template($('#listTemplate').html()),
            $filter = $('.filter');

        var $field = $('#datepicker'),
            $pikaDay = '';

        if($filter[0]) {
            $('select').niceSelect();

            // // remove mCustomScrollbar tabindex
            // setTimeout(() => {
            //     $('.mCustomScrollBox').prop('tabindex', '-1');
            // }, 1000);

            // $('.nice-select .list').mCustomScrollbar({
            //     theme: 'minimal-dark'
            // });
        }
        
        if($listContainer[0]) {
            var search = window.location.search;
            var dateFil = _self.qs('date');
            var catFil = _self.qs('category');
            
            if (search[0]) {
                if(dateFil) {
                    var dateFil = dateFil.replace(/\+/g, " "),
                        $selectType = $('#datepicker');
                    
                    $selectType.val(dateFil);
                    setTimeout(function() {
                        $selectType.parents('.filter_container').data('value', dateFil);
                    }, 350);
                    $selectType.parents('.filter_container').attr('data-value', dateFil);
                } 

                if(catFil) {
                    var catFil = catFil.replace(/\+/g, " "),
                        $selectType = $('#categoryType');
                    
                    $selectType.val(catFil).niceSelect('update');
                    setTimeout(function() {
                        $selectType.parents('.filter_container').data('value', catFil);
                    }, 350);
                    $selectType.parents('.filter_container').attr('data-value', catFil);
                }
            }
            
            _self.runFilter(false);
        }

        // Filter Call API function
		if ($('.filter_container').length) {
			$('.filter_container').each(function(){
				let $this = $(this);

				if ($this.find('select').length) {
					$this.find('select').on('change', function() {
						var $sel = $(this),
							$selVal = $sel.val();
						setTimeout(function() {
							$.fn.matchHeight._update();
                            $sel.parents('.filter_container').data('value', $selVal);
						}, 350);
                        $sel.parents('.filter_container').attr('data-value', $selVal);
						setTimeout(function() {
							_self.runFilter(false);
						}, 350);
					});
				}

				if ($this.find('#datepicker').length) {
					var picker = new Pikaday({
						field: $('#datepicker')[0],
						onSelect: function(date) {
							$field.value = picker.toString();
							$pikaDay = $field.value;
                            setTimeout(function() {
                                $.fn.matchHeight._update();
                                $field.parents('.filter_container').data('value', $pikaDay);
                            }, 350);
                            $field.parents('.filter_container').attr('data-value', $pikaDay);
							setTimeout(function() {
                                _self.runFilter(false);
                            }, 350);
						}
					});
				}
			});
		}
    }

    runFilter(appendContent) {
		let _self = this,
            append = appendContent || false,
			$container = $('.image-list-card'),
            $filter = $('.filter');

        var $url = window.location,
            $queryStr = window.location.search;

		var activeFilter = _self.getActiveFilter();

        var $paramData = activeFilter;

        // Check URL for query strings
        if($filter.length) {
            if($queryStr[0]) {
                // query string exist
                _self.updateParam();
                window.history.replaceState(null, null, $url + "?" + $.param(activeFilter));
            } else {
                // no query string exists
                window.history.replaceState(null, null, $url + "?" + $.param(activeFilter));
            }
        }

		_self.getMoreItem($container.data('api'), activeFilter, append);
		$.fn.matchHeight._update();
	}

    getMoreItem(url, data, appendContent) {
        let _self = this;

        var $listContainer = $('.image-list-card'),
            $listRow = $('.list-container > .row', $listContainer),
            $loadmore = $('.js-load-more', $listContainer),
            $modalTemplate = doT.template($('#listTemplate').html()),
            append = appendContent || false;

        // var data = {
        //     "pageid": $listContainer.data('pageid')
        // }

        $.ajax({
            url,
            method: 'get',
            data: data,
            dataType: 'json',
            cache: true
        }).done(function(data) {
            let _renderedHTML = $modalTemplate(data);

            if (!append) {
				$listRow.addClass('is-loading').empty();
            }

            setTimeout(function() {
                $listRow.removeClass('is-loading').append(_renderedHTML);

                $('.listing-wrap').fadeIn('slow');

                // Desktop View
                enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
                    match : function() {
                        window.AD.emitter.emit('cardInfo');
                    }
                });

                enquire.register("screen and (min-width: " + (bp.maxMobile + 1) + "px)", {
                    match : function() {
                        $('.item-desc').matchHeight({
                            byRow: false
                        });

                        $('.item-info').matchHeight();
                    }
                });
            }, 300);

            setTimeout(function() {
                if($('.image-list-isotope')[0]) {
                    var $storyCard = $('.image-list-isotope > .list-container > .row > .list-wrap > .img-card');
                    
                    window.AD.emitter.on('masonryCard', function(){
                        var msnry = new Masonry('.list-container > .row', {
                            itemSelector: '.list-wrap',
                            columnWidth: '.list-wrap',
                            gutter: 0,
                            percentPosition: true,
                            horizontalOrder: true
                        });

                        msnry.layout();
            
                        setTimeout(function(){
                            msnry.reloadItems();
                        }, 100);
                    });

                    window.AD.emitter.on('masonryDestroy', function(){
                        var msnry = new Masonry('.list-container > .row', {
                            itemSelector: '.list-wrap',
                            columnWidth: '.list-wrap',
                            gutter: 0,
                            percentPosition: true,
                            horizontalOrder: true
                        });

                        msnry.destroy();
                    });
            
                    // Desktop View
                    enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
                        match : function() {
                            window.AD.emitter.emit('masonryCard');
                            
                            $storyCard.hoverIntent({
                                over: function() {
                                    var $thisCard = $(this),
                                        $carditem = $thisCard.find('.item-readmore__wrap');
                    
                                    $carditem.addClass('is-active');
                                    $carditem.fadeIn();
                                },
                                out: function() {
                                    $(this).find('.item-readmore__wrap').removeClass('is-active');
                                    $(this).find('.item-readmore__wrap').fadeOut();
                                },
                                timeout: 100,
                                sensitivity: 1,
                                interval: 100,
                            });
                        },
                        unmatch : function() {
                            window.AD.emitter.emit('masonryDestroy');
                        }
                    });
                }
            }, 300);

            // Show/Hide Load More Button
            setTimeout(function() {
                $('.list-wrap').each((i,item)=>{
                    if (i < 11) {
                        $('.image-list-card__btn').hide();
                    } else {
                        $('.image-list-card__btn').show();
                    }
                });
            }, 1000);

            // Fallback for IE Objectfit
            var userAgent, ieReg, ie;
            userAgent = window.navigator.userAgent;
            ieReg = /msie|Trident.*rv[ :]*11\./gi;
            ie = ieReg.test(userAgent);

            setTimeout(function(){
                if (ie) {
                    $('.image-container, .img-card-item__image, .home-carousel .home-carousel__slick .slick-image, .home-video .video-image, .j-image img, .home-about__gallery .gallery-image, .banner-carousel .slick-image').each(function () {
                        var $container = $(this),
                        imgUrl = $container.find('img').prop('src');
                        if (imgUrl) {
                            $container
                                .css('backgroundImage', 'url(' + imgUrl + ')')
                                .addClass('compact-object-fit');
                        }
                    });
                }
            }, 300);

            $loadmore.click((e) => {
                e.preventDefault();
                
                let $parent = $(e.currentTarget).parent();

                let $hiddenWrap = $('.list-wrap.hidden');
                
                $hiddenWrap.each((i,item)=>{
                    if (i < 12) {
                        $(item).removeClass('hidden');
                    }

                    if($('.list-wrap.hidden').length == 0) {
                        $parent.hide();
                        return false;
                    }
                });

                if($hiddenWrap.length < 12 )

                setTimeout(function(){
                    window.AD.emitter.emit('cardInfo');
                }, 200);

                if($('.image-list-isotope')[0]) {
                    setTimeout(function(){
                        window.AD.emitter.emit('masonryCard');
                    }, 200);
                }
            });
        });
    }

    paramIsEmpty(value) {
        let _self = this;
        return value == null || value == "";
    }

    updateParam() {
        let _self = this;
        
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState(null, null, clean_uri);
        }
    }

    getActiveFilter() {
		let _self = this,
            $filter = $('.filter_container'),
            $select = $filter.children('.filter-select'),
            $listContainer = $('.image-list-card'),
			$data = {};

        $filter.each(function() {
            let $this = $(this),
                $key = $this.data('key'),
                $value = $this.data('value');
            
            // Check if data is empty
            if (_self.paramIsEmpty($value)) {
                delete $data[$key];
            } else {
                $data[$key] = $value;
            }
        });

        if($listContainer.data('pageid')) {
            $data['pageid'] = $listContainer.data('pageid');
        }

        // console.log($data);
        return $data;
	}

    qs(key) {
		let _self = this,
			query = window.location.search.substring(1),
			parms = query.split('&');

		for (var i=0; i<parms.length; i++) {
			var pos = parms[i].indexOf('=');
			if (pos > 0  && key == parms[i].substring(0,pos)) {
				return parms[i].substring(pos+1);
			}
		}
		return "";
	}
}
