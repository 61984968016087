'use strict';

import $ from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';
import { bp } from '_helper';

export default class HomeAbout {
    constructor() {
        let _self = this;

        var $AboutGallery = $('.home-about__gallery'),
            $homeAbout = $('.about-gallery__slick');

        $homeAbout.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            $AboutGallery.fadeIn();
        });

        $homeAbout.slick({
            infinite: true,
            fade: true,
            dots: true,
            arrows: true,
            mobileFirst: true,
            speed: 800,
            appendDots: $('.about-gallery__dots'),
            appendArrows: $('.about-gallery__arrows'),
            prevArrow: $('.about-gallery__arrows .prev'),
            nextArrow: $('.about-gallery__arrows .next')
        });

        // Mobile
        enquire.register("screen and (max-width: " + bp.maxMobile + "px)", {
            match : function() {
                $AboutGallery.removeAttr('style');
            },
            unmatch : function() {
                $homeAbout.slick('unslick');
                $homeAbout.slick({
                    infinite: true,
                    fade: true,
                    dots: true,
                    arrows: true,
                    mobileFirst: true,
                    speed: 800,
                    appendDots: $('.about-gallery__dots'),
                    appendArrows: $('.about-gallery__arrows'),
                    prevArrow: $('.about-gallery__arrows .prev'),
                    nextArrow: $('.about-gallery__arrows .next')
                });
            }
        });

        // Tablet
        enquire.register("screen and (min-width: " + (bp.maxMobile + 1) + "px)", {
            match : function() {
                $homeAbout.slick('unslick');
                $homeAbout.slick({
                    infinite: true,
                    fade: true,
                    dots: true,
                    arrows: true,
                    mobileFirst: true,
                    speed: 800,
                    appendDots: $('.about-gallery__dots'),
                    appendArrows: $('.about-gallery__arrows'),
                    prevArrow: $('.about-gallery__arrows .prev'),
                    nextArrow: $('.about-gallery__arrows .next')
                });
            },
            unmatch : function() {
                $AboutGallery.removeAttr('style');
            }
        });

        // Desktop View
        enquire.register("screen and (min-width:" + bp.minDesktop +"px)", {
			match : function() {
                $homeAbout.slick('unslick');
                $homeAbout.slick({
                    infinite: true,
                    fade: true,
                    dots: true,
                    arrows: true,
                    mobileFirst: true,
                    speed: 800,
                    appendDots: $('.about-gallery__dots'),
                    appendArrows: $('.about-gallery__arrows'),
                    prevArrow: $('.about-gallery__arrows .prev'),
                    nextArrow: $('.about-gallery__arrows .next')
                });
			},
            unmatch : function() {
                $AboutGallery.removeAttr('style');
            }
        });
    }
}
